import { ReactElement } from 'react';
import { Box, Flex } from 'reflexbox';
import { Button, ModalWindow } from '~/components';
import { SmallWarningIcon } from '~/icons/WarningIcon';
import { UnderlineBtn } from '../../styles';
import { useTranslation } from 'react-i18next';

interface SavingConfirmationModalProps {
  options: {
    needOverwrite: boolean;
    needMultipleScheds: boolean;
    hasEditScheds: boolean;
    hasDeleteExceptions: boolean;
  }
  open: boolean;
  onClose: () => void;
  sendSchedules(): void;
}

export function SavingConfirmationModal({
  onClose,
  open,
  options,
  sendSchedules,
}: SavingConfirmationModalProps): ReactElement | null {
  const { t } = useTranslation();

  return open ? (
    <ModalWindow
      style={{ padding: '0', overflowX: 'hidden', width: '550px' }}
      borderTop
      onClickOutside={onClose}
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center" padding="40px">
        <Box width="100%">
          <Flex>
            <SmallWarningIcon />
            <h4 style={{ fontWeight: 'bold', marginLeft: '10px' }}>{t('desejaSalvar')}</h4>
          </Flex>
          <ul style={{ color: '#8b8b8b', hyphens: 'auto' }}>
            {options.needOverwrite && <li>{t('alertaConflitoProgDams')}</li>}
            {options.needMultipleScheds && <li>{t('alertaPrecisaMultiplosCards')}</li>}
            {options.hasEditScheds && <li>{t('alertaSubstituicaoSched')}</li>}
            {options.hasDeleteExceptions && <li>{t('alertaSubstituicaoExcept')}</li>}
          </ul>
          <Flex marginTop="40px" alignItems="center" justifyContent="space-between">
            <Button
              style={{ width: '200px' }}
              variant="primary"
              onClick={sendSchedules}
            >
              {t('prosseguir')}
            </Button>
            <UnderlineBtn onClick={onClose} style={{ cursor: 'pointer' }}>
              {t('cancelar')}
            </UnderlineBtn>
          </Flex>
        </Box>
      </Flex>
    </ModalWindow>
  ) : null;
}
