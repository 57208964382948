import {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClearState, DriError, DriItemList, DriOffline, Exception, FilterFormData, GroupDriList, Schedule, StateOpts,
  ValidateSchedsOptions,
} from './types';
import { apiCall, ApiResps } from '~/providers';
import { verifyValueDefinedNotEmpty } from '../MultipleDevProg/helpers/verifications';
import { toast } from 'react-toastify';
import {
  buildDeleteRequest,
  LIMIT_DRIS_SENDING_SCHEDS, uniqueByDriId,
} from './constants';
import {
  getListCities, getListRoomTypes, getListStates, getListUnits,
} from 'helpers/genericHelper';
import { Button, Loader } from 'components';
import { ConfirmDeleteProgException } from './components/ConfirmDeleteProgExceptionModal';
import { Flex } from 'reflexbox';
import { MultipleProgFilter } from './MultipleProgFilter';
import { MultipleProgItemList } from './MultipleProgItemList';
import { SmallTrashIcon } from 'icons/Trash';
import { DriSchedulesContainer } from './components/DriSchedulesContainer';
import { useDriProgrammingContext } from './context/ProgrammingsContext';
import { useSaveDriSchedules } from './hooks/useSaveDriSchedules';
import { ProgrammingProgress } from '../MultipleDevProg/components/ProgrammingProgress';
import { batchRequests } from '~/helpers/batchRequests';
import { useCommonPropsContext } from './context/CommonPropertiesContext';

interface MultipleProgContentProps {
  clientId?: number;
  unitId?: number;
}

export function MultipleProgContent({
  clientId,
  unitId,
}: Readonly<MultipleProgContentProps>): ReactElement {
  const { t } = useTranslation();
  const [options, setOptions] = useState<StateOpts>({
    citiesListOpts: [],
    unitsListOpts: [],
    statesListOpts: [],
    roomTypesListOpts: [],
  });

  const [isFetching, setIsFetching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [groupedDriList, setGroupedDriList] = useState<GroupDriList>();
  const { selectedDevType } = useCommonPropsContext();

  const {
    loading: isSending,
    progressState,
    sendSchedules,
  } = useSaveDriSchedules();
  const { fetchScheds, refetchScheds } = useDriProgrammingContext();

  const allOptions = useMemo(() => ({
    cities: options.citiesListOpts,
    states: options.statesListOpts,
    units: options.unitsListOpts,
    roomTypes: options.roomTypesListOpts,
  }), [options]);

  function groupDrisList(driList: ApiResps['/dri/get-dris-list']['list']): void {
    const newGroupList: GroupDriList = {};

    for (const dri of driList) {
      const newDri = { ...dri, checked: false };

      if (!newGroupList[dri.UNIT_ID]) {
        newGroupList[dri.UNIT_ID] = {
          CITY_NAME: dri.CITY_NAME,
          dris: [newDri],
          STATE_NAME: dri.STATE_NAME,
          UNIT_ID: dri.UNIT_ID,
          UNIT_NAME: dri.UNIT_NAME,
        };
      } else {
        newGroupList[dri.UNIT_ID].dris.push(newDri);
      }
    }

    setGroupedDriList(newGroupList);
  }

  function handleProgrammingStatus(programming: string | string[]): boolean | undefined {
    if (programming === 'Com programação') return true;
    if (programming === 'Sem programação') return false;

    return undefined;
  }

  function handleApplicationInput(): string[] {
    if (selectedDevType === 'Fancoil e VAV') {
      return ['fancoil', 'vav'];
    }

    return ['carrier-ecosplit'];
  }

  async function getDrisListByUnit(): Promise<void> {
    try {
      setIsFiltering(true);
      const { list: drisList } = await apiCall('/dri/get-dris-list', {
        onlyWithAutomation: true,
        application: handleApplicationInput(),
        unitIds: unitId ? [unitId] : undefined,
      });
      groupDrisList(drisList);
      fetchScheds(drisList.map(({ DRI_ID }) => DRI_ID));
    } catch (error) {
      console.log(error);
      toast.error(t('erroBuscarDris'));
    } finally {
      setIsFiltering(false);
    }
  }

  async function getDrisList(data: FilterFormData): Promise<void> {
    try {
      setIsFiltering(true);
      const { list: drisList } = await apiCall('/dri/get-dris-list', {
        onlyWithAutomation: true,
        application: handleApplicationInput(),
        cityIds: verifyValueDefinedNotEmpty(data.cities),
        stateIds: verifyValueDefinedNotEmpty(data.states),
        unitIds: unitId ? [unitId] : verifyValueDefinedNotEmpty(data.units.map((item) => Number(item))),
        clientIds: clientId ? [clientId] : undefined,
        searchTerms: verifyValueDefinedNotEmpty(data.searchState) ? data.searchState?.map((value) => value.toLowerCase()) : undefined,
        rTypeIds: verifyValueDefinedNotEmpty(data.roomTypes) ? data.roomTypes.map((rType) => Number(rType)) : undefined,
        status: verifyValueDefinedNotEmpty(data.connections),
        programmingStatus: handleProgrammingStatus(data.programming),
      });

      groupDrisList(drisList);
      fetchScheds(drisList.map(({ DRI_ID }) => DRI_ID));
    } catch (error) {
      console.log(error);
      toast.error(t('erroBuscarDris'));
    } finally {
      setIsFiltering(false);
    }
  }

  function onSubmitFilter(data: FilterFormData): void {
    getDrisList(data);
  }

  function onCloseConfirmModal(): void {
    setOpenConfirmDeleteModal(false);
  }

  function onOpenConfirmModal(): void {
    setOpenConfirmDeleteModal(true);
  }

  function getSelectedDris(): DriItemList[] {
    if (!groupedDriList) return [];

    return Object.values(groupedDriList).flatMap((driList) => driList.dris.filter((dri) => dri.checked));
  }

  function deselectAllDevices(): void {
    setGroupedDriList((previousValues) => {
      const newGroupedDriList = { ...previousValues };

      for (const [key, value] of Object.entries(newGroupedDriList)) {
        newGroupedDriList[key] = {
          ...value,
          dris: value.dris.map((dri) => (dri.checked ? { ...dri, checked: false } : dri)),
        };
      }

      return newGroupedDriList;
    });
  }

  function deselectDevicesSuccess(drisErrors: DriError[]): void {
    const groupDrisByUnit: Record<number, string[]> = {};

    deselectAllDevices();

    if (drisErrors.length === 0) {
      return;
    }

    for (const driError of drisErrors) {
      if (groupDrisByUnit[driError.UNIT_ID]) {
        groupDrisByUnit[driError.UNIT_ID] = [...groupDrisByUnit[driError.UNIT_ID], driError.DRI_ID];
      } else {
        groupDrisByUnit[driError.UNIT_ID] = [driError.DRI_ID];
      }
    }

    setGroupedDriList((previousValues) => {
      const newGroupedDriList = { ...previousValues };

      for (const [key, values] of Object.entries(groupDrisByUnit)) {
        if (!newGroupedDriList[key]) continue;
        newGroupedDriList[key] = {
          ...newGroupedDriList[key],
          dris: newGroupedDriList[key].dris.map((item: DriItemList) => (values.includes(item.DRI_ID) ? { ...item, checked: true } : item)),
        };
      }

      return newGroupedDriList;
    });
  }

  async function onSubmitConfirmDelete(data: ClearState): Promise<void> {
    if (!isValidDevices()) return;

    setIsDeleting(true);
    const dataSend: {DRI_ID: string, UNIT_ID: number, promise: () => Promise<string>, schedule: boolean}[] = [];
    const driErrors: DriError[] = [];
    const drisOffline: DriOffline[] = [];

    if (!groupedDriList) return;

    const selectedDevices = getSelectedDris();

    for (const device of selectedDevices) {
      if (data.exceptions) {
        dataSend.push({
          DRI_ID: device.DRI_ID,
          UNIT_ID: device.UNIT_ID,
          promise: () => apiCall('/dri/delete-all-exceptions', { DRI_ID: device.DRI_ID }),
          schedule: false,
        });
      }

      if (data.schedules) {
        dataSend.push({
          DRI_ID: device.DRI_ID,
          UNIT_ID: device.UNIT_ID,
          promise: () => apiCall('/dri/delete-all-schedules', { DRI_ID: device.DRI_ID }),
          schedule: true,
        });
      }
    }

    const promises = dataSend.map((data) => buildDeleteRequest(data, driErrors, drisOffline));
    await batchRequests(promises, LIMIT_DRIS_SENDING_SCHEDS);

    const successDriIds = [...new Set(dataSend.filter((item) => !driErrors.some((driError) => driError.DRI_ID === item.DRI_ID)
    && !drisOffline.some((driOffline) => driOffline.DRI_ID === item.DRI_ID)).map((item) => item.DRI_ID))];

    if (successDriIds.length > 0) {
      toast.success(t('progEnviadaSucesso'));
      refetchScheds(successDriIds);
    }

    setIsDeleting(false);
    onCloseConfirmModal();
    handleSendingErrors(driErrors, drisOffline);
  }

  async function getOptionsLists(): Promise<void> {
    setIsFetching(true);

    const [cities, states, units, roomTypes] = await Promise.all([
      getListCities(),
      getListStates(),
      getListUnits(clientId),
      getListRoomTypes(clientId),
    ]);

    setOptions({
      citiesListOpts: cities,
      statesListOpts: states,
      unitsListOpts: units,
      roomTypesListOpts: roomTypes,
    });

    setIsFetching(false);
  }

  function onCheckDriList(unitId: number, newDrisList: DriItemList[]): void {
    setGroupedDriList((previousValue) => {
      const newGrouped = { ...previousValue };

      if (!previousValue) return;

      newGrouped[unitId] = {
        ...newGrouped[unitId],
        dris: newDrisList,
      };

      return newGrouped;
    });
  }

  function isValidDevices(): boolean {
    const selectedDevices = getSelectedDris();
    if (selectedDevices.length === 0) {
      toast.warn(t('semDispositivoSelecionad'));
      return false;
    }
    return true;
  }

  function handleSendingErrors(drisErrors: DriError[], drisOffline: DriOffline[]): void {
    deselectDevicesSuccess(drisErrors);

    if (drisErrors.length === 0 && drisOffline.length === 0) return;

    const uniqueDrisErrors = uniqueByDriId(drisErrors);
    const uniqueDrisOffline = uniqueByDriId(drisOffline);

    if (uniqueDrisErrors.length > 0) {
      let errorMessage = t('erroProgDispositivos');
      for (const { DRI_ID, message } of uniqueDrisErrors) {
        console.log(`${DRI_ID}:${message}`);
        errorMessage += `\n${DRI_ID}`;
      }
      toast.error(errorMessage, { closeOnClick: false, draggable: false });
    }

    if (uniqueDrisOffline.length > 0) {
      const message = t('dispositivoOfflineDesmarcado', { devs: uniqueDrisOffline.map(({ DRI_ID }) => DRI_ID).join(', ') });
      toast.error(message, { closeOnClick: false, draggable: false });
    }

    throw new Error('Error sending schedules');
  }

  async function onSendSchedules(schedules: Schedule[], exceptions: Exception[], validateSchedsOpts: ValidateSchedsOptions): Promise<void> {
    toast.info(t('iniciadoProgramacaoEmMassa'), { autoClose: 3000 });
    const selectedDevices = getSelectedDris();
    const { drisErrors, drisOffline } = await sendSchedules(selectedDevices, { schedules, exceptions }, validateSchedsOpts);

    const successDriIds = selectedDevices.filter((item) => !drisErrors.some((driError) => driError.DRI_ID === item.DRI_ID)
    && !drisOffline.some((driOffline) => driOffline.DRI_ID === item.DRI_ID)).map((item) => item.DRI_ID);

    if (successDriIds.length > 0) {
      toast.success(t('progEnviadaSucesso'));
      refetchScheds(successDriIds);
    }

    handleSendingErrors(drisErrors, drisOffline);
  }

  useEffect(() => {
    if (unitId) {
      getDrisListByUnit();
      return;
    }
    if (Object.values(allOptions).every((item) => item.length === 0)) {
      getOptionsLists();
    }
  }, []);

  if (isFetching) {
    return (
      <Loader />
    );
  }

  if (isSending) {
    return (
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Loader />
        <ProgrammingProgress currentDevices={progressState.currentDevices} totalDevices={progressState.totalDevices} />
      </Flex>
    );
  }

  return (
    <>
      <ConfirmDeleteProgException
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmDelete}
        open={openConfirmDeleteModal}
        loading={isDeleting}
      />
      <Flex
        flexDirection="column"
        style={{ gap: '20px 0' }}
      >
        <div style={{ marginTop: '20px' }}>
          <h1 style={{ fontSize: '20px', fontWeight: 'bold', margin: 0 }}>
            {t('selecionarMaquinasTitulo')}
          </h1>
          <h2 style={{ color: '#8b8b8b', fontSize: '14px', margin: 0 }}>
            {t('selecionarMaquinasSubTitulo')}
          </h2>
        </div>
        <MultipleProgFilter
          loading={isFiltering}
          onSubmit={onSubmitFilter}
          options={allOptions}
          clientId={clientId}
          unitId={unitId}
        />
        {groupedDriList && (
          <>
            <MultipleProgItemList
              clientId={clientId}
              groupDriList={groupedDriList ? Object.values(groupedDriList) : []}
              onCheckDriList={onCheckDriList}
            />
            <Flex>
              <Button
                style={{ width: '260px', fontSize: '10px', marginLeft: 'auto' }}
                variant="red-inv-border"
                onClick={onOpenConfirmModal}
              >
                <Flex alignItems="center" justifyContent="center">
                  <SmallTrashIcon color="#DC0E01" />
                  <span style={{ marginLeft: '10px' }}>{t('limparExistentesUpper')}</span>
                </Flex>
              </Button>
            </Flex>
            <DriSchedulesContainer
              clientId={clientId}
              validDevices={isValidDevices}
              onSendSchedules={onSendSchedules}
              selectedDevType={selectedDevType}
              disabledSaveButton={isDeleting}
            />
          </>
        )}
      </Flex>
    </>
  );
}
