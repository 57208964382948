import { t } from 'i18next';
import { SelectSearchOption } from 'react-select-search';
import {
  DriError, DriOffline, DriSchedListResponse, SelectDriTypes,
} from './types';
import { toast } from 'react-toastify';
import { DevFullInfo } from 'store';
import {
  checkExceptsCardsConflicts, checkSchedsCardsConflicts, ExceptionInfo, ScheduleInfo,
} from 'helpers/schedule';
import { DriException, DriSchedule } from 'pages/Analysis/SchedulesModals/DRI_ScheduleModal';
import { isAxiosError } from '~/helpers/error';

interface ValidateSchedsResponse {
  schedulesToEdit: {id: number, days: string}[],
  schedulesToDelete: number[],
  exceptionsToDelete: number[]
}

export const LIMIT_DRIS_SENDING_SCHEDS = 3;
export const MAXIMUM_EXCEPTIONS = 6;
export const MAXIMUM_SCHEDULES = 6;

const KeyCodes = {
  comma: 188,
  slash: 191,
  enter: [10, 13],
};

export const delimiters = [
  ...KeyCodes.enter, KeyCodes.comma, KeyCodes.slash,
];

export const connectionsOptions = [
  { value: 'ONLINE', name: t('online') },
  { value: 'LATE', name: t('late') },
  { value: 'OFFLINE', name: t('offline') },
];

export const programmingOptions = [
  { value: 'Com programação', name: t('comProgramacao') },
  { value: 'Sem programação', name: t('semProgramacao') },
  { value: 'Todos', name: t('todos') },
];

export const flexGrowFilterFields = [1, 1, 1, 0, 0];

export const generateDefaultDriCfg = (selectedDevType: 'Splitão Inverter' | 'Fancoil e VAV'): { application: string, protocol: string } => (selectedDevType === 'Splitão Inverter' ? {
  application: 'carrier-ecosplit',
  protocol: 'carrier-ecosplit',
} : {
  application: 'fancoil-bac-2000',
  protocol: 'modbus-rtu',
});

export const filterOptions = (
  options: SelectSearchOption[], extraCondition?: boolean,
) => (
  query: string,
): SelectSearchOption[] => {
  if (options.length > 0) {
    return options
      .filter((item) => {
        if (item.name?.length > 0) {
          return (
            (item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
              || (extraCondition ?? true)
          );
        }
        return false;
      });
  }
  return options;
};

export const showToastErrorDevices = (drisErrors: DriError[]): void => {
  if (drisErrors.length === 0) return;

  let message = t('erroProgDispositivos');
  for (const driError of drisErrors) {
    if (!message.includes(driError.DRI_ID)) {
      console.error(`${driError.DRI_ID}:${driError.message}`);
      message += `\n${driError.DRI_ID}`;
    }
  }
  toast.error(message, { closeOnClick: false, draggable: false });
};

export const splitProgrammingToSchedulesExceptions = (scheds?: DriSchedListResponse): {
  schedules: DriSchedListResponse,
  exceptions: DriSchedListResponse,
} => {
  const schedules: DriSchedListResponse = [];
  const exceptions: DriSchedListResponse = [];

  if (!scheds) {
    return {
      schedules,
      exceptions,
    };
  }

  for (const sched of scheds) {
    if (sched.EXCEPTION_DATE) {
      exceptions.push(sched);
    } else {
      schedules.push(sched);
    }
  }

  return {
    schedules,
    exceptions,
  };
};

export function generateDefaultDevInfo(clientId: number, selectedDevType: SelectDriTypes): DevFullInfo {
  return {
    CLIENT_ID: clientId,
    dri: {
      varsCfg: generateDefaultDriCfg(selectedDevType),
      automationCfg: {
        AUTOMATION_INTERVAL: 5,
      },
    },
    GROUP_ID: 1,
  } as unknown as DevFullInfo;
}

export const buildDriScheduleInfo = (schedule: DriSchedule, isNewSched?: boolean): ScheduleInfo => ({
  BEGIN_TIME: schedule.BEGIN_TIME,
  DAYS: schedule.DAYS,
  END_TIME: schedule.END_TIME,
  ID: isNewSched ? undefined : schedule.SCHED_ID,
});

export const buildDriExceptionInfo = (exception: DriException, isNewSched?: boolean): ExceptionInfo => ({
  ACTIVE: exception.ACTIVE,
  BEGIN_TIME: exception.BEGIN_TIME,
  END_TIME: exception.END_TIME,
  EXCEPTION_DATE: exception.EXCEPTION_DATE,
  REPEAT_YEARLY: exception.EXCEPTION_REPEAT_YEARLY,
  ID: isNewSched ? undefined : exception.SCHED_ID,
});

export const validateScheds = (schedules: ScheduleInfo[], exceptions: ExceptionInfo[], showToastError = true): boolean => {
  if (checkSchedsCardsConflicts(schedules).hasConflict) {
    if (showToastError) toast.error(t('conflitoDalSched'));
    return false;
  }

  if (checkExceptsCardsConflicts(exceptions).hasConflict) {
    if (showToastError) toast.error(t('conflitoDalExcept'));
    return false;
  }

  return true;
};

export const checkSchedsConflicts = (
  scheds: DriSchedListResponse,
  newScheds: {schedules: ScheduleInfo[], exceptions: ExceptionInfo[]},
): ValidateSchedsResponse => {
  const { exceptions, schedules } = splitProgrammingToSchedulesExceptions(scheds);
  const oldExceptionCards = exceptions.map((except) => buildDriExceptionInfo(except));
  const oldScheduleCards = schedules.map((schedule) => buildDriScheduleInfo(schedule));
  const validateSchedules = newScheds.schedules.length > 0 ? checkSchedsCardsConflicts([...newScheds.schedules, ...oldScheduleCards]) : undefined;
  const validateExceptions = newScheds.exceptions.length > 0 ? checkExceptsCardsConflicts([...newScheds.exceptions, ...oldExceptionCards]) : undefined;

  const schedulesToEdit = validateSchedules?.schedsConflicts.filter((item) => !item.deleteSched).map(({ schedId, days }) => ({ id: schedId, days })) ?? [];
  const schedulesToDelete = validateSchedules?.schedsConflicts.filter((item) => item.deleteSched).map(({ schedId }) => schedId) ?? [];

  return {
    exceptionsToDelete: validateExceptions?.exceptionsIdsToDelete ?? [],
    schedulesToEdit,
    schedulesToDelete,
  };
};

export const uniqueByDriId = <T extends {DRI_ID: string}>(array: T[]) : T[] => {
  const map = new Map<string, T>();

  for (const item of array) {
    if (!map.has(item.DRI_ID)) {
      map.set(item.DRI_ID, item);
    }
  }

  return Array.from(map.values());
};

export const verifyDriErrorMessage = (error: Error, errorSearchText: string): boolean => isAxiosError(error)
    && error.response?.status === 400
    && error.response.data
    && typeof error.response.data === 'object'
    && 'errorMessage' in error.response.data
    && error.response.data.errorMessage.includes(errorSearchText);

export const verifyDriErrorData = (error: Error, errorSearchText: string): boolean => isAxiosError(error)
    && error.response?.status === 400
    && error.response?.data
    && typeof error.response.data === 'string'
    && error.response.data.includes(errorSearchText);

export const verifyErrorsRequestProgramming = (error: Error, drisErrors: DriError[], drisOffline: DriOffline[], device: { DRI_ID: string, UNIT_ID: number }): void => {
  if (verifyDriErrorMessage(error, 'não está online') || verifyDriErrorData(error, 'não está online')) {
    drisOffline.push({ DRI_ID: device.DRI_ID, UNIT_ID: device.UNIT_ID });
  } else if (verifyDriErrorData(error, 'programações sobrepostas')) {
    drisErrors.push({
      DRI_ID: device.DRI_ID,
      UNIT_ID: device.UNIT_ID,
      message: t('erroProgramacoesSobrepostas'),
    });
  } else {
    drisErrors.push({
      DRI_ID: device.DRI_ID,
      UNIT_ID: device.UNIT_ID,
      message: t('erroEnvioProgramacaoEmMassaPreenchimento'),
    });
  }
};

export const buildDeleteRequest = (
  dataSend: {DRI_ID: string, UNIT_ID: number, promise: () => Promise<string>, schedule: boolean},
  driErrors: DriError[],
  drisOffline: DriOffline[],
) => (): Promise< string | void> => dataSend.promise().catch((error) => {
  verifyErrorsRequestProgramming(error, driErrors, drisOffline, { DRI_ID: dataSend.DRI_ID, UNIT_ID: dataSend.UNIT_ID });
});
