import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'reflexbox';
import { SmallTrashIcon } from 'icons/Trash';
import { ClearAllAddedModal } from './ClearAllAddedModal';
import { Button } from 'components';
import { SavingConfirmationModal } from './SavingConfirmationModal';
import { DriSchedulesContent } from './DriSchedulesContent';
import { formatISODateToFormattedDateString } from 'helpers/dates';
import {
  buildDriExceptionInfo, buildDriScheduleInfo, validateScheds, checkSchedsConflicts,
  MAXIMUM_EXCEPTIONS,
  MAXIMUM_SCHEDULES,
} from '../constants';
import { useDriProgrammingContext } from '../context/ProgrammingsContext';
import {
  Exception, Schedule, SelectDriTypes, ValidateSchedsOptions,
} from '../types';
import { LimitExceedTooltip } from 'pages/Analysis/SchedulesModals/DRI_Shedule';

interface DriSchedulesContainerProps {
  validDevices: () => boolean;
  onSendSchedules: (schedules: Schedule[], exceptions: Exception[], validateSchedsOpts: ValidateSchedsOptions) => void;
  selectedDevType: SelectDriTypes;
  disabledSaveButton?: boolean;
  clientId?: number;
}

export function DriSchedulesContainer({
  validDevices,
  onSendSchedules,
  selectedDevType,
  clientId,
  disabledSaveButton = false,
}: Readonly<DriSchedulesContainerProps>): ReactElement {
  const { t } = useTranslation();
  const [isException, setIsException] = useState(false);
  const [openClearAllModal, setOpenClearAllModal] = useState(false);
  const [openSavingConfirmModal, setOpenSavingConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [exceptions, setExceptions] = useState<Exception[]>([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [savingConfirmOptions, setSavingConfirmOptions] = useState({
    hasEditScheds: false,
    hasDeleteExceptions: false,
  });
  const [validateSchedsOpts, setValidateSchedsOpts] = useState<ValidateSchedsOptions>({
    exceptionsToDelete: [],
    schedulesToEdit: [],
    schedulesToDelete: [],
  });
  const { scheds } = useDriProgrammingContext();

  const limitExceeded = isException ? exceptions.length >= MAXIMUM_EXCEPTIONS : schedules.length >= MAXIMUM_SCHEDULES;

  function onClickClearAddedButton(): void {
    setOpenClearAllModal(true);
  }

  function onCloseClearAllModal(): void {
    setOpenClearAllModal(false);
  }

  function onCloseSavingConfirmModal(): void {
    setOpenSavingConfirmModal(false);
  }

  function onCloseAddModal(): void {
    setOpenAddModal(false);
  }

  function onClickAddEditProgramming(): void {
    setOpenAddModal(true);
  }

  const handleSaveSched = async () => {
    try {
      if (!validDevices()) return;
      const filteredSchedsList = Object.entries(scheds).flatMap(([, values]) => values);
      const scheduleCards = schedules.map((item) => buildDriScheduleInfo(item, true));
      const exceptionCards = exceptions.map((item) => buildDriExceptionInfo(item, true));
      const validScheds = validateScheds(scheduleCards, exceptionCards);
      const checkConflicts = checkSchedsConflicts(filteredSchedsList, {
        schedules: scheduleCards, exceptions: exceptionCards,
      });
      if (validScheds) {
        setSavingConfirmOptions({
          hasDeleteExceptions: checkConflicts.exceptionsToDelete.length > 0,
          hasEditScheds: checkConflicts.schedulesToEdit.length > 0 || checkConflicts.schedulesToDelete.length > 0,
        });
        setValidateSchedsOpts({
          exceptionsToDelete: checkConflicts.exceptionsToDelete,
          schedulesToDelete: checkConflicts.schedulesToDelete,
          schedulesToEdit: checkConflicts.schedulesToEdit,
        });
        setOpenSavingConfirmModal(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  async function onAddSched(sched?: Schedule | Exception): Promise<void> {
    if (!sched) { return; }
    if (sched.EXCEPTION_DATE) {
      setExceptions((previousValues) => [
        ...previousValues,
        {
          ...sched,
          EXCEPTION_DATE: formatISODateToFormattedDateString(sched.EXCEPTION_DATE) as string,
          SCHED_ID: previousValues.length,
        },
      ]);
    } else {
      setSchedules((previousValues) => [...previousValues, { ...sched, SCHED_ID: previousValues.length }]);
    }
  }

  async function onUpdateSched(sched?: Schedule | Exception): Promise<void> {
    if (!sched) { return; }
    if (sched.EXCEPTION_DATE) {
      setExceptions((previousValues) => previousValues.map((item) => (
        item.SCHED_ID !== sched.SCHED_ID ? item : {
          ...sched,
          EXCEPTION_DATE: formatISODateToFormattedDateString(sched.EXCEPTION_DATE) as string,
        })));
    } else {
      setSchedules((previousValues) => previousValues.map((item) => (item.SCHED_ID !== sched.SCHED_ID ? item : sched)));
    }
  }

  function onChangeShowException(value: boolean): void {
    setIsException(value);
  }

  function onDeleteSched(sched?: Schedule | Exception): void {
    if (!sched) { return; }
    if (sched.EXCEPTION_DATE) {
      setExceptions((previousValues) => previousValues.filter((item) => item.SCHED_ID !== sched.SCHED_ID));
    } else {
      setSchedules((previousValues) => previousValues.filter((item) => item.SCHED_ID !== sched.SCHED_ID));
    }
  }

  function onClearAll(): void {
    if (isException) {
      setExceptions([]);
    } else {
      setSchedules([]);
    }
    onCloseClearAllModal();
  }

  function onClickSendSchedules(): void {
    try {
      onSendSchedules(schedules, exceptions, validateSchedsOpts);
      onClearAll();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <ClearAllAddedModal
        isException={isException}
        onClickClearAll={onClearAll}
        onClose={onCloseClearAllModal}
        open={openClearAllModal}
      />
      <SavingConfirmationModal
        open={openSavingConfirmModal}
        onClose={onCloseSavingConfirmModal}
        sendSchedules={onClickSendSchedules}
        options={{
          needOverwrite: false,
          needMultipleScheds: false,
          hasDeleteExceptions: savingConfirmOptions.hasDeleteExceptions,
          hasEditScheds: savingConfirmOptions.hasEditScheds,
        }}
      />
      <Flex mt="40px" flexDirection="column">
        <Flex flexDirection="column" width="100%">
          <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
            {t('gerenciarProgramacoes')}
          </span>
          <Flex justifyContent="space-between">
            <Box width="500px" color="#8b8b8b">
              {t('acoesMaquinas')}
            </Box>
            <Flex flexDirection="row">
              <LimitExceedTooltip disabled={limitExceeded} isException={isException}>
                <Button
                  style={{ width: '260px', fontSize: '10px' }}
                  onClick={onClickAddEditProgramming}
                  variant={limitExceeded ? 'disabled' : 'primary'}
                  disabled={limitExceeded}
                >
                  {isException
                    ? t('adicionarExcept') : t('adicionarSched')}
                </Button>
              </LimitExceedTooltip>
              <Button
                variant="red-inv-border"
                style={{ width: '260px', marginLeft: '10px' }}
                onClick={onClickClearAddedButton}
                disabled={isException ? exceptions.length === 0 : schedules.length === 0}
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                >
                  <SmallTrashIcon color="#DC0E01" />
                  <span
                    style={{ marginLeft: '10px', fontSize: '10px' }}
                  >
                    {isException ? t('limparExceptsAdicionadas') : t('limparSchedAdicionadas')}
                  </span>
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <DriSchedulesContent
            clientId={clientId ?? 0}
            exceptions={exceptions}
            schedules={schedules}
            onCloseModal={onCloseAddModal}
            openAddModal={openAddModal}
            actions={{
              onAddSched,
              onUpdateSched,
              onDeleteSched,
            }}
            isException={isException}
            onChangeShowException={onChangeShowException}
            selectedDevType={selectedDevType}
          />
        </Flex>
        <Flex marginTop="10px" alignItems="center" justifyContent="flex-start">
          <Button
            style={{ width: '90px', padding: '8px 15px' }}
            variant={(loading || disabledSaveButton) ? 'disabled' : 'primary'}
            onClick={handleSaveSched}
            disabled={loading || disabledSaveButton}
          >
            {t('SALVAR').toUpperCase()}
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
